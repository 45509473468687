$(document).foundation();

$(document).ready( function() {
  
   /**
   * Functions on page load
   */

  initJs();
  
  /**
   * Functions on window resize
   */

  $(window).resize( $.debounce(500, function() {
    reInitJs();
  }));
  
  /**
   * Functions on scrolling
   */

  $(window).scroll( function() {
    //headerControle();
    //hideLayer();
  });

});


/** 
 * Initialisierung (on load)
 * Re-Initalisierung (on resize)
 */

var w = false;
var breakpoint = 600;

function initJs() {
  resizeFn();      // resize Funktionen on page load
  toggleNav();     // Navigation ein- und ausblenden
} 


function reInitJs() {
  var wNew = $(window).width();
  if( (wNew >= breakpoint && w < breakpoint) || (wNew < breakpoint && w >= breakpoint) )  { resizeFn(); }
  /* debug
  if(wNew >= breakpoint && w < breakpoint) { alert("change to large device: "+w+"-"+wNew); w = wNew; return true; }
  if(wNew < breakpoint && w >= breakpoint) { alert("change to small device: "+w+"-"+wNew); w = wNew; return true; }
  alert("no change: "+w+"-"+wNew); w = wNew; return false;
  */
} 


function resizeFn() {
  w = $(window).width();

  if(w >= breakpoint) {
    //alert("large device: "+w);

  } else {
    //alert("small device: "+w);

  }
} 


/** 
 * Functions
 */

  /* Toggle Referenzen Layer  */
  
  function toggleNav() {
    $("#nav-trigger").click( function() {
      var trigger = $(this);
      $('#mainnav').slideToggle(500, function() {
        $(trigger).toggleClass('open')
      });
    });

    $('.menu li').on('click', function(){
      $('#mainnav').slideToggle(500, function() {
        $('#nav-trigger').toggleClass('open')
      });
  });

  }
  
  
  // end function toggleLayer()

